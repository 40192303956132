const config = {
  firebase: {
    apiKey: 'AIzaSyCjaW6XjdBNGds-Do5T1Ddw4qY_Eu6nbc8',
    authDomain: 'magazzn-73900.firebaseapp.com',
    projectId: 'magazzn-73900',
    storageBucket: 'magazzn-73900.appspot.com',
    messagingSenderId: '562053793282',
    appId: '1:562053793282:web:29362b31b78d59497a091b',
    measurementId: 'G-P6Y9XRWRJD',
  },
  stripePublicAPIKey: 'pk_live_51JxHRrCyK2pdFboMNYMODz97UrIeIR8YANrq4hFM3JEhgs3oUkxiVSbPgXTT8ZL6ZfLffOIkrQYfQeT5B86aCguG00hEx1AyOe',
  facebookAppId: '589215375048594',
  google: {
    apiUrl: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCvguDT4xWUfOpDU88TunmuT07h-FkPvR4&libraries=places,geometry',
  },
};


export default config;
