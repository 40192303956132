import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';


const IssuePageItem = React.memo(({ title, onEdit, onDelete }) => (
  <Box
    display="flex"
    flex={1}
    flexDirection="row"
    justifyContent="space-between"
    alignItems="center"
    pt={1 / 2}
    pb={1 / 2}
  >
    <Typography>
      {title}
    </Typography>

    <Box>
      <IconButton onClick={onEdit}>
        <EditIcon />
      </IconButton>

      <IconButton onClick={onDelete}>
        <DeleteIcon color="secondary" />
      </IconButton>
    </Box>
  </Box>
));

IssuePageItem.propTypes = {
  title: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

IssuePageItem.defaultProps = {};


export default IssuePageItem;
