import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles(() => ({
  sliderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '80px',
  },
}));
