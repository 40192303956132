import { PAGE_TYPE } from 'constants/product-config';


export const getFakePreviewContent = (values) => ({
  pageTypeId: PAGE_TYPE.MAGAZINE?.id,
  content: {
    config: {
      ...values,
      logo: {
        url: values.logoImage,
      },
      imprintLogo: {
        url: values.imprintLogoImage,
      },
    },
    issues: [{
      showIssueName: true,
      name: 'Issue Name',
      pages: [
        'Editors Letter',
        'Discover Local',
        'Sponsored Page',
        'Another Article',
        'Local Map',
        'Contact Us',
      ],
    }],
  },
});
