import AppStoreIcon from 'assets/images/store-icons/appStore.png';
import PlayStoreIcon from 'assets/images/store-icons/playStore.png';


export const STORE_LINKS = [
  {
    id: 'appStore',
    href: 'https://apps.apple.com/us/app/magazzn/id1601445288',
    icon: AppStoreIcon,
  },
  {
    id: 'playStore',
    href: 'https://play.google.com/store/apps/details?id=com.kappow.magazzn',
    icon: PlayStoreIcon,
  },
];

export const dynamicLink = 'https://magazzn.page.link';
export const websiteDomain = 'https://app.magazzn.com';
export const appStoreId = '1601445288';
export const iosBundleId = 'com.kappow.magazzn';
export const apn = 'com.kappow.magazzn';
