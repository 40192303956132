import update from 'immutability-helper';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { move } from 'ramda';

import {
  Typography,
  Box,
  IconButton, Button,
} from '@material-ui/core';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import SortableList from '@pro/web-common/components/sortable-list';
import SortableListItem from '@pro/web-common/components/sortable-list-item';
import InfoSection from '@pro/web-common/components/info-section';

import { getPlainTextFromHtml } from '@pro/web-common/utils';

import infoJson from 'constants/info.json';

import IssuePageItem from '../issue-page-item';
import './styles.css';


const IssueItem = React.memo(({ name, pages, onEdit, onDelete, onPagesUpdate, onEditPage, onAddPage }) => {
  const handlePagesSortEnd = useCallback(({ oldIndex, newIndex }) => {
    const nextPages = move(oldIndex, newIndex, pages);

    onPagesUpdate(nextPages);
  }, [pages, onPagesUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageDelete = useCallback((index) => {
    const nextPages = update(pages, { $splice: [[index, 1]] });

    onPagesUpdate(nextPages);
  }, [pages, onPagesUpdate]);

  return (
    <Box
      width="100%"
    >
      <Box
        display="flex"
        flex={1}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        pt={1 / 2}
        pb={1 / 2}
      >
        <Typography
          className="ck-content-reset-all-styles"
        >
          {getPlainTextFromHtml(name)}
        </Typography>

        <Box>
          <IconButton onClick={onEdit}>
            <EditIcon />
          </IconButton>

          <IconButton onClick={onDelete}>
            <DeleteIcon color="secondary" />
          </IconButton>
        </Box>
      </Box>

      <Box
        display="flex"
        flex={1}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        pt={1 / 2}
        pb={1 / 2}
        ml={3}
      >
        <SortableList
          onSortEnd={handlePagesSortEnd}
          useDragHandle
          lockAxis="y"
        >
          {pages.map((page, index) => (
            <SortableListItem
              key={index} // eslint-disable-line react/no-array-index-key
              index={index}
            >
              <IssuePageItem
                onEdit={() => onEditPage(page)}
                onDelete={() => handlePageDelete(index)}
                {...page}
              />
            </SortableListItem>
          ))}
        </SortableList>
      </Box>

      <Box
        mt={1}
        textAlign="right"
      >
        <InfoSection
          infoMessage={infoJson.product.addPageButton}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={onAddPage}
          >
            Add Page
          </Button>
        </InfoSection>
      </Box>
    </Box>
  );
});


IssueItem.propTypes = {
  name: PropTypes.string.isRequired,
  pages: PropTypes.arrayOf(PropTypes.shape({})),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onPagesUpdate: PropTypes.func.isRequired,
  onEditPage: PropTypes.func.isRequired,
  onAddPage: PropTypes.func.isRequired,
};

IssueItem.defaultProps = {
  pages: [],
};


export default IssueItem;
