import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';

import {
  Box,
  Collapse,
  FormControl,
} from '@material-ui/core';

import { ProductIdContext, UserRoleContext } from '@pro/web-common/containers/providers';
import { DEMO_PREFIX, PRODUCTS_PATH } from '@pro/web-common/constants/storage';

import CKEditorCustomized from '@pro/web-common/components/ck-editor-customized';
import SingleImageUploader from '@pro/web-common/components/single-image-uploader';
import ColorPicker from '@pro/web-common/components/color-picker';
import Select from '@pro/web-common/components/select';
import InfoSection from '@pro/web-common/components/info-section';
import { colorPropsSchema } from '@pro/web-common/utils/style';
import Checkbox from '@pro/web-common/components/checkbox';

import { typefaceValues } from 'constants/brand-config';

import infoJson from 'constants/info.json';

import { styles } from './styles';


const PageSectionContentEditor = React.memo(({ isOpened, typeface, titleBackgroundColor, image, html, onChange, isSubsection, showTitle }) => {
  const { isDemo } = useContext(UserRoleContext);
  const productId = useContext(ProductIdContext);
  const classes = styles();

  const [handleContentChange] = useDebouncedCallback((data) => {
    onChange({ html: data });
  }, 100);

  const onTypefaceChange = useCallback(({ target: { value } }) => {
    onChange({ typeface: value });
  }, [onChange]);

  const handleShowTitleChange = useCallback(({ target: { checked } }) => {
    onChange({ showTitle: checked });
  }, [onChange]);

  return (
    <Collapse
      in={isOpened}
      mountOnEnter
      unmountOnExit
    >
      <Box className={classes.container}>
        <Checkbox
          id="showTitle"
          name="showTitle"
          value={showTitle}
          label="Show title"
          onChange={handleShowTitleChange}
        />
      </Box>

      <Box className={classes.container}>
        <Box className={classes.selectContainer}>
          <InfoSection infoMessage={isSubsection ? infoJson.product.sectionTypefacePageInput : infoJson.product.typefacePageInput}>
            <FormControl
              variant="outlined"
              fullWidth
            >
              <Select
                id="typeface"
                name="typeface"
                options={typefaceValues}
                value={typeface}
                onChange={onTypefaceChange}
                withFontFamily
                label="Font / Typeface"
              />
            </FormControl>
          </InfoSection>
        </Box>

        <InfoSection infoMessage={isSubsection ? infoJson.product.sectionBackgroundInput : infoJson.product.pageBackgroundInput}>
          <ColorPicker
            label="Background Colour"
            color={titleBackgroundColor}
            onColorChange={(value) => onChange({ titleBackgroundColor: value })}
          />
        </InfoSection>
      </Box>

      <Box className={classes.container}>
        <SingleImageUploader
          image={image}
          onImageDelete={() => onChange({ image: null })}
          onImageAdd={(fileData) => onChange({ image: fileData })}
        />

        <Box className={classes.editorContainer}>
          <CKEditorCustomized
            data={html}
            onChange={handleContentChange}
            imagesPathPrefix={`${isDemo ? DEMO_PREFIX : ''}${PRODUCTS_PATH}/${productId}`}
          />
        </Box>
      </Box>
    </Collapse>
  );
});

PageSectionContentEditor.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  typeface: PropTypes.string,
  image: PropTypes.shape({
    filePath: PropTypes.string,
  }),
  html: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  titleBackgroundColor: colorPropsSchema.isRequired,
  isSubsection: PropTypes.bool,
  showTitle: PropTypes.bool,
};

PageSectionContentEditor.defaultProps = {
  typeface: '',
  image: null,
  html: '',
  isSubsection: false,
  showTitle: true,
};


export default PageSectionContentEditor;
