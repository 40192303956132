import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Container, Grid, Typography, Box, Button } from '@material-ui/core';

import PageTitle from '@pro/web-common/components/page-title';

import userActions from '@pro/web-common/core/user/actions';

import { styles } from './styles';


const DemoIntro = React.memo(({ signOut }) => {
  const classes = styles();

  const onSignUp = useCallback((event) => {
    event.preventDefault();
    signOut({ withRedirectToSignUp: true });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth="md">
      <PageTitle
        title="Magazzn Demo"
        subtitle="Magazzn is a new way to publish 'magazine' type content in an app"
        large
      />

      <Grid
        container
        justify="space-between"
        spacing={6}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <PageTitle
            title="Really Simple App Content Management System"
            subtitle="Our app CMS requires no coding skills at all ..."
          />

          <Box className={classes.container}>
            <iframe
              title="youtube video"
              src="https://www.youtube.com/embed/Kq_aBgIMKB4"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>

          <Typography>
            Magazzn app is free to subsribers/readers - they scan a QR or tap a short code link.
            Magazzn opens immediately with your magazine in view.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <PageTitle
            title="How you manage content in your app"
            subtitle="This is a quick overview of the web admin - where you manage app content"
          />

          <Typography>
            In the admin CMS you can easily manage all your content and messaging.
            All new or edited content is immediately available.
            Try the DEMO below!
          </Typography>
        </Grid>


        <Grid
          item
          xs={12}
        >
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Box
              display={{
                xs: 'none',
                sm: 'block',
              }}
              ml={2}
              mr={2}
            >
              <Button
                component={NavLink}
                color="inherit"
                variant="outlined"
                to="/configure-brand"
              >
                Demo Admin
              </Button>
            </Box>

            <Box
              display={{
                xs: 'none',
                sm: 'block',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={onSignUp}
              >
                Sign Up
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
});

DemoIntro.propTypes = {
  signOut: PropTypes.func.isRequired,
};

DemoIntro.defaultProps = {};

const mapDispatchToProps = {
  signOut: userActions.signOut,
};


export default connect(null, mapDispatchToProps)(DemoIntro);
