import QR from 'assets/images/support/Magazzn-ManualPro-qr.png';


export const SUPPORT_ITEMS_FIRST_COLUMN = [
  {
    title: 'Download our Support/FAQs app',
    questions: [
      {
        question: 'Set Up and User Instructions',
        answer: `
        <p>Our sister app <strong>ManualPro</strong> has full Set Up and User Instructions, including video and FAQs.</p>
        <p>Think of it like a print user manual, on your phone.</p>
        <p>The links below will <strong>download ManualPro from the App/Play Stores</strong> and open directly on the Magazzn User Manual.</p>
        <p>Either scan the QR if you are reading this on a PC, or tap the QR of you are already on mobile.</p>
        <p><a href="https://manualproco.page.link/jfPk"><img src="${QR}" alt="ManualPro user Manual for Magazzn App" /></a></p>
        <p>You can also use this link to download the User Instructions:</p>
        <p><strong><a href="https://manualproco.page.link/jfPk" style="color: black; text-decoration: underline;">Set Up and User Instructions<a/></strong></p>
        `,
      },
    ],
  },
];

export const SUPPORT_ITEMS_SECOND_COLUMN = [
  {
    title: 'Need support right now?',
    questions: [
      {
        question: 'Talk to our support team via chat.',
        answer: `
        <p>To set up a Demo or get help with any of our features click or tap the chat icon at the bottom of this page.</p>
        <p><strong>You'll be through to a human in seconds.</strong></p>
        <p></p>
        `,
      },
    ],
  },
];
