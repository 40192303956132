import * as Yup from 'yup';


export const logoFormats = [
  'image/png',
  'image/x-png',
  'image/jpeg',
];

export const formValidationSchema = () => Yup.object().shape({
  name: Yup.string().required(),
  coverImage: Yup.string(),
  newCoverImageFile: Yup.mixed()
    .nullable()
    .test(
      'fileFormat',
      'Unsupported Format',
      (value) => value ? logoFormats.includes(value.type) : true,
    ),
  coverTitleHtml: Yup.string().nullable(),
  startDate: Yup.date().nullable(),
  endDate: Yup.date().nullable(),
  showIssueName: Yup.bool().required(),
});
