import React from 'react';
import { Helmet } from 'react-helmet';


export default {
  default: (
    <Helmet
      defaultTitle="Magazzn.com - create and publish your own app magazine."
    >
      <meta
        name="keywords"
        content="magazine, publish, app, internal communications, company reports, niche, hobby, local"
      />
      <meta
        name="description"
        content="Publish your own niche, company, local or hobby magazine in a fully branded app"
      />
    </Helmet>
  ),
  home: (
    <Helmet>
      <title>Publish your own app magazine</title>
      <meta
        name="keywords"
        content="magazine, publish, app, internal communications, company reports, niche, hobby, local"
      />
      <meta
        name="description"
        content="Publish your own niche, company, local or hobby magazine in a fully branded app"
      />
    </Helmet>
  ),
  signUp: (
    <Helmet>
      <title>SignUp 14 Days Free Digital Magazine App</title>
      <meta
        name="keywords"
        content="sign up, magazine, publish, app, internal communications, company reports, niche, hobby, local"
      />
      <meta
        name="description"
        content="Sign up to publish your own niche, company, local or hobby magazine in a fully branded app"
      />
    </Helmet>
  ),
  logIn: (
    <Helmet>
      <title>Login to manage your digital magazine app content and messaging</title>
      <meta
        name="keywords"
        content="magazine, publish, app, internal communications, company reports, niche, hobby, local"
      />
      <meta
        name="description"
        content="Login to manage added value content in your magazine"
      />
    </Helmet>
  ),
  forgotPassword: (
    <Helmet>
      <title>Password recovery for Magazzn</title>
      <meta
        name="keywords"
        content="password, recovery, reset, attendee, app"
      />
      <meta
        name="description"
        content="Recover your password here to admin your Magazine app."
      />
    </Helmet>
  ),
  aboutUs: (
    <Helmet>
      <title>About Us</title>
      <meta
        name="description"
        content="About Us"
      />
    </Helmet>
  ),
  support: (
    <Helmet>
      <title>Magazine App Support 24/7</title>
      <meta
        name="keywords"
        content="documentation, support, messaging, book, direct, booking, chat, publish, how to, content"
      />
      <meta
        name="description"
        content="Support for your Magazzn app"
      />
    </Helmet>
  ),
  privacy: (
    <Helmet>
      <title>Privacy Statement</title>
      <meta
        name="keywords"
        content="audience, data, privacy, policy, GDPR"
      />
      <meta
        name="description"
        content="How we deal with privacy and data."
      />
    </Helmet>
  ),
  terms: (
    <Helmet>
      <title>Terms and Conditions</title>
      <meta
        name="keywords"
        content="terms, conditions, guest, app, magazine"
      />
      <meta
        name="description"
        content="Our terms for using Magazzn app builder."
      />
    </Helmet>
  ),
  gdpr: (
    <Helmet>
      <title>GDPR Information</title>
      <meta
        name="keywords"
        content="magazine app, gdpr, statement"
      />
      <meta
        name="description"
        content="How we manage magazine data and our gdpr policy."
      />
    </Helmet>
  ),
};
