import update from 'immutability-helper';
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { move } from 'ramda';

import {
  Box,
  Typography,
} from '@material-ui/core';

import SortableList from '@pro/web-common/components/sortable-list';
import SortableListItem from '@pro/web-common/components/sortable-list-item';

import { getFirestoreTimestampDate } from '@pro/web-common/utils/date';

import IssueItem from '../issue-item';

import { styles } from './styles';


const IssuesListing = React.memo(({ issues, onEdit, onDelete, onUpdate, onAddPage, onEditPage, isArchivedIssuesVisible }) => {
  const classes = styles();

  const today = new Date();
  today.setUTCHours(23, 59);
  const now = today.getTime();

  const handleIssuesSortEnd = useCallback(({ oldIndex, newIndex }) => {
    const nextIssues = move(oldIndex, newIndex, issues);

    onUpdate(nextIssues);
  }, [issues, onUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePagesUpdate = useCallback((nextPages, index) => {
    const nextIssues = update(issues, { [index]: { $merge: { pages: nextPages } } });

    onUpdate(nextIssues);
  }, [issues, onUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  const issuesWithActiveFlag = useMemo(() => issues.map((issue) => ({
    ...issue,
    isActive: issue.endDate ? getFirestoreTimestampDate(issue.endDate).getTime() > now : true,
  })), [issues, now]);

  const isAnyActiveIssue = useMemo(() => {
    if (isArchivedIssuesVisible) {
      return true;
    }

    return issuesWithActiveFlag.some(({ isActive }) => isActive);
  }, [issuesWithActiveFlag, isArchivedIssuesVisible]);

  return (
    <>
      {!isAnyActiveIssue ? (
        <Box
          textAlign="center"
          p={2}
        >
          <Typography variant="body2">There is no active Issues</Typography>
        </Box>
      ) : (
        <Box ml={2}>
          <SortableList
            onSortEnd={handleIssuesSortEnd}
            useDragHandle
            lockAxis="y"
          >
            {issuesWithActiveFlag.map((issue, index) => isArchivedIssuesVisible || issue.isActive ? (
              <SortableListItem
                key={index} // eslint-disable-line react/no-array-index-key
                index={index}
              >
                <Box className={classes.itemContainer}>
                  <IssueItem
                    onEdit={() => onEdit({ ...issue, index })}
                    onDelete={() => onDelete(index)}
                    onPagesUpdate={(nextPages) => handlePagesUpdate(nextPages, index)}
                    onEditPage={(page) => onEditPage(page, index)}
                    onAddPage={() => onAddPage(index)}
                    name={issue.name}
                    pages={issue.pages}
                  />
                </Box>
              </SortableListItem>
            ) : null)}
          </SortableList>
        </Box>
      )}
    </>
  );
});

IssuesListing.propTypes = {
  issues: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onAddPage: PropTypes.func.isRequired,
  onEditPage: PropTypes.func.isRequired,
  isArchivedIssuesVisible: PropTypes.bool.isRequired,
};

IssuesListing.defaultProps = {
  issues: [],
};


export default IssuesListing;
