import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import {
  Box,
  Button,
  Collapse,
} from '@material-ui/core';

import Checkbox from '@pro/web-common/components/checkbox';
import InfoSection from '@pro/web-common/components/info-section';

import { generateId, findIndexByProp } from '@pro/web-common/utils';

import infoJson from 'constants/info.json';
import { DEFAULT_MAGAZINE_CONFIG, DEFAULT_MAGAZINE_ISSUES } from 'content/texts';

import IssueListing from './issues-listing';
import ConfigureMagazineModal from './configure-magazine-modal';
import ConfigureIssueModal from './configure-issue-modal';
import ConfigurePageModal from './configure-issue-page-modal';


const MagazinePageEditor = React.memo(({ isOpened, initialValues: { issues, config }, onSubmit, onError }) => {
  const [magazineData, setMagazineData] = useState(config || DEFAULT_MAGAZINE_CONFIG);
  const [issuesData, setIssuesData] = useState(issues || DEFAULT_MAGAZINE_ISSUES);
  const [isConfigureMagazineModalOpen, setIsConfigureMagazineModalOpen] = useState(false);
  const [isConfigureIssueModalOpen, setIsConfigureIssueModalOpen] = useState(false);
  const [isConfigurePageModalOpen, setIsConfigurePageModalOpen] = useState(false);
  const [issueModalData, setIssueModalData] = useState(null);
  const [pageModalData, setPageModalData] = useState(null);
  const [pageModaIssueIndex, setPageModalIssueIndex] = useState(null);
  const [isArchivedIssuesVisible, setIsArchivedIssuesVisible] = useState(false);

  const handleSubmit = useCallback(() => onSubmit({ config: magazineData, issues: issuesData }), [onSubmit, magazineData, issuesData]);
  const handleSubmitWithoutClosingEditor = useCallback((nextMagazineData, nextIssuesData) => {
    onSubmit({ config: nextMagazineData, issues: nextIssuesData }, true);
  }, [onSubmit]);

  const handleMagazineUpdate = useCallback((data) => {
    setMagazineData(data);
    setIsConfigureMagazineModalOpen(false);
    handleSubmitWithoutClosingEditor(data, issuesData);
  }, [handleSubmitWithoutClosingEditor, issuesData]);

  const handleEditIssue = useCallback((data) => {
    setIssueModalData(data);
    setIsConfigureIssueModalOpen(true);
  }, []);

  const handleEditPage = useCallback((page, issueIndex) => {
    setPageModalData({ page: { ...page, isExist: true } });
    setPageModalIssueIndex(issueIndex);
    setIsConfigurePageModalOpen(true);
  }, []);

  useEffect(() => {
    if (!isConfigureIssueModalOpen) {
      setIssueModalData(null);
    }
  }, [isConfigureIssueModalOpen]);

  useEffect(() => {
    if (!isConfigurePageModalOpen) {
      setPageModalData(null);
    }
  }, [isConfigurePageModalOpen]);

  const handleIssueUpdate = useCallback((index, data) => {
    let nextIssuesData;
    if (index !== undefined) {
      nextIssuesData = update(issuesData, { [index]: { $merge: data } });
    } else {
      nextIssuesData = update(issuesData, { $unshift: [{ ...data, pages: [] }] });
    }

    setIssuesData(nextIssuesData);
    setIsConfigureIssueModalOpen(false);
    handleSubmitWithoutClosingEditor(magazineData, nextIssuesData);
  }, [handleSubmitWithoutClosingEditor, magazineData, issuesData]);

  const handleUpdatePage = useCallback(async (page, issueIndex) => {
    const prevPages = issuesData[issueIndex].pages || [];
    const pageIndex = findIndexByProp(prevPages, 'id', page.id);
    const nextPages = update(prevPages, {
      [pageIndex]: {
        $set: page,
      },
    });

    const nextIssue = {
      ...issuesData[issueIndex],
      pages: nextPages,
    };

    const nextIssues = update(issuesData, { [issueIndex]: { $set: nextIssue } });

    setIssuesData(nextIssues);
    setIsConfigurePageModalOpen(false);
    handleSubmitWithoutClosingEditor(magazineData, nextIssues);
  }, [handleSubmitWithoutClosingEditor, magazineData, issuesData]);

  const handleCreatePage = useCallback(async (page, issueIndex) => {
    const pageId = generateId();

    const prevPages = issuesData[issueIndex].pages || [];
    const nextPages = update(prevPages, { $push: [{
      ...page,
      id: pageId,
    }] });

    const nextIssue = {
      ...issuesData[issueIndex],
      pages: nextPages,
    };

    const nextIssues = update(issuesData, { [issueIndex]: { $set: nextIssue } });

    setIssuesData(nextIssues);
    setIsConfigurePageModalOpen(false);
    handleSubmitWithoutClosingEditor(magazineData, nextIssues);
  }, [handleSubmitWithoutClosingEditor, magazineData, issuesData]);

  const handlePageUpdate = useCallback((page, issueIndex) => {
    if (page.isExist) {
      handleUpdatePage(page, issueIndex);
    } else {
      handleCreatePage(page, issueIndex);
    }
  }, [handleUpdatePage, handleCreatePage]);

  const handleIssueDelete = useCallback((index) => {
    setIssuesData(update(issuesData, { $splice: [[index, 1]] }));
  }, [issuesData]);

  const handleIssuesUpdate = useCallback((data) => {
    setIssuesData(data);
  }, []);

  const handleConfigureMagazineClick = useCallback(() => setIsConfigureMagazineModalOpen(true), []);
  const handleAddIssue = useCallback(() => setIsConfigureIssueModalOpen(true), []);
  const handleAddPage = useCallback((issueIndex) => {
    setPageModalIssueIndex(issueIndex);
    setIsConfigurePageModalOpen(true);
  }, []);

  return (
    <Collapse
      in={isOpened}
      mountOnEnter
      unmountOnExit
    >

      <Box
        mt={2}
        textAlign="right"
      >
        <InfoSection
          infoMessage={infoJson.product.magazineSection}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfigureMagazineClick}
          >
            Configure Magazine
          </Button>
        </InfoSection>
      </Box>

      <Box
        mt={1}
        textAlign="right"
      >
        <InfoSection
          infoMessage={infoJson.product.showArchivedIssuesButton}
        >
          <Checkbox
            id="isArchivedIssuesVisible"
            name="isArchivedIssuesVisible"
            value={isArchivedIssuesVisible}
            label="Show Archived Issues"
            onChange={({ target: { checked } }) => setIsArchivedIssuesVisible(checked)}
          />
        </InfoSection>
      </Box>

      <Box
        mt={2}
        mb={2}
      >
        <IssueListing
          issues={issuesData}
          onEdit={handleEditIssue}
          onDelete={handleIssueDelete}
          onUpdate={handleIssuesUpdate}
          onAddPage={handleAddPage}
          onEditPage={handleEditPage}
          isArchivedIssuesVisible={isArchivedIssuesVisible}
        />

        <Box
          mt={1}
          textAlign="right"
        >
          <InfoSection
            infoMessage={infoJson.product.addIssueButton}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddIssue}
            >
              Add Issue
            </Button>
          </InfoSection>
        </Box>

        <Box
          mt={1}
          textAlign="right"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      </Box>

      {isConfigureMagazineModalOpen && (
        <ConfigureMagazineModal
          initialValues={magazineData}
          onClose={() => setIsConfigureMagazineModalOpen(false)}
          onSubmit={handleMagazineUpdate}
        />
      )}

      {isConfigureIssueModalOpen && (
        <ConfigureIssueModal
          isEdit={!!issueModalData}
          initialValues={issueModalData}
          magazineData={magazineData}
          onClose={() => setIsConfigureIssueModalOpen(false)}
          onSubmit={handleIssueUpdate}
        />
      )}

      {isConfigurePageModalOpen && (
        <ConfigurePageModal
          isEdit={!!pageModalData}
          initialValues={pageModalData}
          issueIndex={pageModaIssueIndex}
          onClose={() => setIsConfigurePageModalOpen(false)}
          onSubmit={handlePageUpdate}
          onError={onError}
        />
      )}
    </Collapse>
  );
});


MagazinePageEditor.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    issues: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
    })),
    config: PropTypes.shape({}),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

MagazinePageEditor.defaultProps = {

};


export default MagazinePageEditor;
