import SignIn from '@pro/web-common/pages/sign-in';
import SignUp from '@pro/web-common/pages/sign-up';
import VerifyEmail from '@pro/web-common/pages/auth-actions';
import ForgotPassword from '@pro/web-common/pages/forgot-password';
import ConfigureBrand from '@pro/web-common/pages/configure-brand';
import AdminHome from '@pro/web-common/pages/admin-home';
import AdminUsers from '@pro/web-common/pages/admin-users';
import AddProduct from '@pro/web-common/pages/add-product';
import AdminSubscriptions from '@pro/web-common/pages/admin-subscriptions';
import ProductLink from '@pro/web-common/pages/product-link';
import EditProduct from '@pro/web-common/pages/edit-product';
import Messages from '@pro/web-common/pages/messages';
import QuickMessages from '@pro/web-common/pages/quick-messages';
import Privacy from '@pro/web-common/pages/privacy';
import Terms from '@pro/web-common/pages/terms';
import Support from '@pro/web-common/pages/support';
import SelectUser from '@pro/web-common/pages/select-user';
import Statistics from '@pro/web-common/pages/statistics';
import Resources from '@pro/web-common/pages/resources';
import ProfileHome from '@pro/web-common/pages/profile-home';
// import CreateAdmin from '@pro/web-common/pages/create-admin';
// import CreateDemo from '@pro/web-common/pages/create-demo';

import DemoIntro from 'pages/demo-intro';
import Home from 'pages/home';
import AboutUs from 'pages/about-us';
import Gdpr from 'pages/gdpr';

import { ROLES } from '@pro/web-common/core/user/constants';


const ROUTES = [
  {
    title: 'Home',
    key: 'HOME',
    path: '/',
    exact: true,
    component: Home,
  },
  {
    title: 'Home',
    key: 'HOME_DUPLICATE',
    path: '/home',
    exact: true,
    component: Home,
  },
  {
    title: 'Sign Up',
    key: 'SIGN_UP',
    path: '/sign-up',
    exact: true,
    component: SignUp,
    unauthorizedOnly: true,
    hero: {
      image: 'hero1.jpeg',
    },
  },
  {
    title: 'Log In',
    key: 'LOGIN',
    path: '/login',
    exact: true,
    component: SignIn,
    unauthorizedOnly: true,
    hero: {
      image: 'hero1.jpeg',
    },
  },
  {
    title: 'Forgot Password',
    key: 'FORGOT_PASSWORD',
    path: '/forgot-password',
    exact: true,
    component: ForgotPassword,
    unauthorizedOnly: true,
    hero: {
      image: 'hero1.jpeg',
    },
  },
  {
    title: 'Auth Actions',
    key: 'VERIFY_EMAIL',
    path: '/auth',
    component: VerifyEmail,
    hero: {
      image: 'hero1.jpeg',
    },
  },
  {
    title: 'Configure Branding',
    key: 'CONFIGURE_BRAND',
    path: '/configure-brand',
    exact: true,
    component: ConfigureBrand,
    authorizedOnly: true,
    allowForRoles: [ROLES.OWNER, ROLES.DEMO, ROLES.SUPER_ADMIN],
  },
  {
    title: 'My Home',
    key: 'ADMIN_HOME',
    path: '/admin-home',
    exact: true,
    component: AdminHome,
    authorizedOnly: true,
    allowForRoles: [ROLES.OWNER, ROLES.DEMO, ROLES.SUPER_ADMIN, ROLES.MANAGER],
  },
  {
    title: 'My Users',
    key: 'ADMIN_USERS',
    path: '/admin-users',
    exact: true,
    component: AdminUsers,
    authorizedOnly: true,
    allowForRoles: [ROLES.OWNER, ROLES.DEMO, ROLES.SUPER_ADMIN],
  },
  {
    title: 'My Subscriptions',
    key: 'ADMIN_SUBSCRIPTIONS',
    path: '/admin-subscriptions',
    exact: true,
    component: AdminSubscriptions,
    authorizedOnly: true,
    allowForRoles: [ROLES.OWNER, ROLES.DEMO, ROLES.SUPER_ADMIN],
  },
  {
    title: 'Add a new Magazine',
    key: 'ADD_PRODUCT',
    path: '/add-product',
    exact: true,
    component: AddProduct,
    authorizedOnly: true,
    allowForRoles: [ROLES.OWNER, ROLES.DEMO, ROLES.SUPER_ADMIN],
  },
  {
    title: 'Edit Magazine',
    key: 'EDIT_PRODUCT',
    path: '/edit-product/:id',
    exact: true,
    component: EditProduct,
    authorizedOnly: true,
  },
  {
    title: 'Magazine',
    key: 'PRODUCT',
    path: '/product/:id',
    exact: true,
    component: ProductLink,
    authorizedOnly: false,
  },
  {
    title: 'Magazine',
    key: 'PRODUCT',
    path: '/app',
    exact: true,
    component: ProductLink,
    authorizedOnly: false,
  },
  {
    title: 'Messages',
    key: 'MESSAGES',
    path: '/messages',
    exact: true,
    component: Messages,
    authorizedOnly: true,
  },
  {
    title: 'Quick Messages',
    key: 'QUICK_MESSAGES',
    path: '/quick-messages',
    exact: true,
    component: QuickMessages,
    authorizedOnly: true,
  },
  {
    title: 'About Us',
    key: 'ABOUT_US',
    path: '/about',
    exact: true,
    component: AboutUs,
  },
  {
    title: 'Support',
    key: 'SUPPORT',
    path: '/support',
    exact: true,
    component: Support,
  },
  {
    title: 'Privacy',
    key: 'PRIVACY',
    path: '/privacy',
    exact: true,
    component: Privacy,
  },
  {
    title: 'Terms',
    key: 'TERMS',
    path: '/terms',
    exact: true,
    component: Terms,
  },
  {
    title: 'Gdpr',
    key: 'GDPR',
    path: '/gdpr',
    exact: true,
    component: Gdpr,
  },
  {
    title: 'Select User',
    key: 'SELECT_USER',
    path: '/select-user',
    exact: true,
    component: SelectUser,
    allowForRoles: [ROLES.SUPER_ADMIN],
    authorizedOnly: true,
    notLoggedByUserOnly: true,
  },
  {
    title: 'Statistics',
    key: 'STATS',
    path: '/stats',
    exact: true,
    component: Statistics,
    authorizedOnly: true,
    allowForRoles: [ROLES.OWNER, ROLES.DEMO, ROLES.SUPER_ADMIN],
  },
  {
    title: 'Resources',
    key: 'RESOURCES',
    path: '/resources',
    exact: true,
    component: Resources,
    authorizedOnly: true,
  },
  {
    title: 'DemoIntro',
    key: 'DEMO_INTRO',
    path: '/demo-intro',
    exact: true,
    component: DemoIntro,
    authorizedOnly: true,
  },
  {
    title: 'My Home',
    key: 'PROFILE_HOME',
    path: '/profile-home',
    exact: true,
    component: ProfileHome,
    authorizedOnly: true,
    allowForRoles: [ROLES.SUPER_ADMIN, ROLES.PROFILE],
  },
  // NOTE: !!!DELETE THIS ROUTE FROM APP AFTER ADMIN CREATION!!!
  //  {
  //    title: 'Create Admin',
  //    key: 'CREATE_ADMIN',
  //    path: '/create-admin',
  //    exact: true,
  //    component: CreateAdmin,
  //  },
  // NOTE: !!!DELETE THIS ROUTE FROM APP AFTER DEMO USER CREATION!!!
  // {
  //   title: 'Create Demo User',
  //   key: 'CREATE_DEMO_USER',
  //   path: '/create-demo-user',
  //   exact: true,
  //   component: CreateDemo,
  // },
];


export default ROUTES;
