export const UPGRADED_PLAN_FEATURES = [
  {
    id: '1',
    label: 'Unlimited Users',
    description: 'Add unlimited Users to Admin TOP UPGRADE PLAN FEATURES',
  },
  {
    id: '2',
    label: 'Unlimited issues / unlimited pages',
    description: 'Add new issues with unlimied pages anytime',
  },
  {
    id: '3',
    label: 'Enhanced Messaging With Image and Push Notifications',
    description: 'Use advanced features to send instant or timed messages with images, video and PDFs',
  },
  {
    id: '4',
    label: 'Add brand logo to all app views',
    description: 'Add your company/brand logo to all pages of app',
  },
  {
    id: '5',
    label: 'Search Terms/Tags',
    description: 'List search tags so anyone can find your magazine',
  },
  {
    id: '6',
    label: 'Added value content',
    description: 'Publish added value content in app',
  },
];

export const PLAN_TYPE_FREE_ID = 'free';
export const PLAN_TYPE_SINGLE_ID = 'single';
export const PLAN_TYPE_MULTI_TEN_ID = 'multiTen';
export const PLAN_TYPE_BANK_TRANSFER = 'bankTransfer';

export const ACTIVE_SUBSCRIPTION_LIMIT = null;

export const PLAN_TYPE_PRICES = {
  [PLAN_TYPE_SINGLE_ID]: {
    EUR: {
      price: '79.00',
    },
    GBP: {
      price: '79.00',
    },
    USD: {
      price: '89.00',
    },
  },
};

export const PLAN_TYPES = [
  {
    id: PLAN_TYPE_FREE_ID,
    title: 'FREE Trial',
    canAdd: false,
    features: [
      {
        id: 'products_count',
        label: '1 x Magazine',
        description: 'Publish your own app magazine - 30 day free trial FREE TRIAL FEATURES',
      },
      {
        id: 'users_count',
        label: '1 x User',
        description: '1 User to manage your content',
      },
      {
        id: 'messaging',
        label: 'Quick Push Messaging',
        description: 'Instantly message your audience with Push Notifications, including a link',
      },
      {
        id: 'image_messaging',
        label: 'Image Messaging',
        description: 'Add images, video and content to the Messages channel in app. Schedule and/or geofence (set radius based on location) messages',
      },
    ],
  },
  {
    id: PLAN_TYPE_SINGLE_ID,
    title: '1 Magazine',
    availableProducts: 1,
    canAdd: true,
    getAddButtonLabel: ({ currencySign, price }) => `Add 1 x Magazine @${currencySign}${price} per month`,
    features: [
      {
        id: 'features',
        label: 'Fully featured app, including;',
        description: 'All features',
      },
      {
        id: 'users',
        label: 'Unlimited Users',
        description: 'Add unlimited Users to admin',
      },
      {
        id: 'messaging',
        label: 'Quick Push Messaging',
        description: 'Instantly message your audience with Push Notifications, including a link',
      },
      {
        id: 'brand',
        label: 'Add brand logo to all app views',
        description: 'Add your company/brand logo to all pages of app',
      },
      {
        id: 'image_messaging',
        label: 'Image Messaging with Push Notifications',
        description: 'Add images, video and content to the Messages channel in app. Schedule and/or geofence (set radius based on location) messages',
      },
      {
        id: 'search_tags',
        label: 'Search Terms/Tags',
        description: 'List search tags so anyone can find your magazine',
      },
    ],
  },
  {
    id: PLAN_TYPE_BANK_TRANSFER,
    title: 'Bank Transfer',
    canAdd: false,
    features: [
      {
        id: 'features',
        label: 'Fully featured app, no feature limits. You currently pay by account (not via Card). If you wish to add more Magazines please contact us via chat to arrange.',
        description: 'You have arranged to pay via account - please use chat to add further magazines.',
      },
    ],
  },
];

export const UPGRADE_PLAN_FEATURES = [
  {
    id: 'features',
    label: 'Fully featured app, including;',
    description: 'All features',
  },
  {
    id: 'users',
    label: 'Unlimited Users',
    description: 'Add unlimited Users to admin',
  },
  {
    id: 'messaging',
    label: 'Quick Push Messaging',
    description: 'Instantly message your audience with Push Notifications, including a link',
  },
  {
    id: 'brand',
    label: 'Add brand logo to all app views',
    description: 'Add your company/brand logo to all pages of app',
  },
  {
    id: 'image_messaging',
    label: 'Image Messaging with Push Notifications',
    description: 'Add images, video and content to the Messages channel in app. Schedule and/or geofence (set radius based on location) messages',
  },
  {
    id: 'search_tags',
    label: 'Search Terms/Tags',
    description: 'List search tags so anyone can find your magazine',
  },
];
