import LinkedInIcon from 'assets/images/social-icons/linkedIn.png';
import KappowIcon from 'assets/images/social-icons/kappow.png';



export const SOCIAL_LINKS = [
  {
    id: 'linkedIn',
    href: 'https://www.linkedin.com/',
    icon: LinkedInIcon,
  },
  {
    id: 'kappowApps',
    href: 'https://www.kappowapps.com/',
    icon: KappowIcon,
  },
];
