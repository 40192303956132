import { generateId } from '@pro/web-common/utils';


export const getSectionDefaultProps = () => ({
  id: generateId(),
  title: '',
  showTitle: true,
  typeface: '',
  html: '',
  image: null,
  isVisible: true,
  titleBackgroundColor: {
    r: 84,
    g: 84,
    b: 84,
    a: 1,
  },
});

export const getSubSectionDefaultProps = () => ({
  id: generateId(),
  title: '',
  showTitle: true,
  typeface: '',
  html: '',
  image: null,
  isVisible: true,
  titleBackgroundColor: {
    r: 84,
    g: 84,
    b: 84,
    a: 1,
  },
});
