import React from 'react';
import PropTypes from 'prop-types';

import {
  DialogContent,
  DialogTitle,
  Box,
  Dialog,
} from '@material-ui/core';

import MagazineForm from '../magazine-form';


const ConfigureMagazineModal = React.memo(({ onClose, onSubmit, initialValues }) => (
  <Dialog
    open
    onClose={onClose}
    aria-labelledby="form-dialog-title"
    maxWidth="lg"
    fullWidth
  >
    <DialogTitle id="form-dialog-title">
      Configure Magazine
    </DialogTitle>

    <DialogContent>
      <Box mb={2}>
        <MagazineForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      </Box>
    </DialogContent>
  </Dialog>
));


ConfigureMagazineModal.propTypes = {
  initialValues: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

ConfigureMagazineModal.defaultProps = {
  initialValues: null,
};


export default ConfigureMagazineModal;
