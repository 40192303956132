import { parseRgbaColor } from '@pro/web-common/utils/style';

import { typefaceValues } from 'constants/brand-config';


const config = ({ rtfColors = [] }) => {
  const parsedRtfColors = rtfColors.map(({ value: color }) => parseRgbaColor(color));

  return ({
    toolbar: {
      items: [
        'heading',
        '|',
        'alignment',
        'bold',
        'italic',
        'fontFamily',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'indent',
        'outdent',
        '|',
        'undo',
        'redo',
      ],
    },
    alignment: {
      options: ['left', 'right', 'center', 'justify'],
    },
    heading: {
      options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        {
          model: 'paragraphSmall',
          view: {
            name: 'p',
            classes: 'ck-heading_paragraph--small',
          },
          title: 'Paragraph (small)',
          class: 'ck-heading_paragraph ck-heading_paragraph--small',
          converterPriority: 'high',
        },
        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
        { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
      ],
    },
    // This value must be kept in sync with the language defined in webpack.config.js.
    language: 'en',
    fontFamily: {
      options: typefaceValues.map(({ value }) => value),
    },
    autoParagraph: false,
    coreStyles_underline: { element: 'span', attributes: { class: 'underline' } },
    keystrokes:
        [
          [13 /* Enter */, 'blur'],
          // [CKEDITOR.SHIFT + 13 /* Shift + Enter */, 'blur'],
        ],
    ...(parsedRtfColors && parsedRtfColors.length > 0 ? (
      {
        fontColor: {
          colors: parsedRtfColors,
          columns: 4,
        },
        fontBackgroundColor: {
          colors: parsedRtfColors,
          columns: 6,
        },
      }
    ) : {}),
  });
};


export default config;
