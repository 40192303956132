import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  editorContainer: {
    flex: 1,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
      marginLeft: 0,
    },
  },
  selectContainer: {
    width: '40%',
    marginRight: theme.spacing(8),
  },
}));
