import React, { useContext, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';

import { Box } from '@material-ui/core';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import { BrandContext } from '@pro/web-common/containers/providers';

import plugins from './plugins';
import defaultConfig from './config';
import { styles } from './styles';


const CKEditorInput = React.memo(({ data, onChange, error, withBrandFont }) => {
  const initialData = useRef(data).current;
  const { brand } = useContext(BrandContext);
  const { typeface, rtfColors } = brand || {};
  const ckEditorConfig = useMemo(() => defaultConfig({ rtfColors }), [rtfColors]);

  const classes = styles({ error, ...(withBrandFont ? { typeface } : {}) });

  const [onEditorChange] = useDebouncedCallback((editorData) => {
    onChange(editorData);
  }, 500);

  return (
    <Box className={`${classes.container} ck-editor-input`}>
      <CKEditor
        className="ck-editor-input"
        editor={ClassicEditor}
        data={initialData}
        onChange={(event, editor) => {
          const editorData = editor.getData();
          onEditorChange(editorData);
        }}
        config={{
          ...ckEditorConfig,
          plugins,
        }}
      />
    </Box>
  );
});


CKEditorInput.propTypes = {
  data: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  withBrandFont: PropTypes.bool,
};

CKEditorInput.defaultProps = {
  data: '',
  error: false,
  withBrandFont: true,
};


export default CKEditorInput;
