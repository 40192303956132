import React from 'react';
import PropTypes from 'prop-types';

import LargeDialog from '@pro/web-common/components/large-dialog';

import IssuePageForm from '../issue-page-form';


const ConfigureIssuePageModal = React.memo(({ isEdit, onClose, onSubmit, onError, initialValues, issueIndex }) => (
  <LargeDialog
    open
    onClose={onClose}
    disableEnforceFocus
  >
    <IssuePageForm
      isEdit={isEdit}
      initialValues={initialValues}
      issueIndex={issueIndex}
      onSubmit={onSubmit}
      onClose={onClose}
      onError={onError}
    />
  </LargeDialog>
));


ConfigureIssuePageModal.propTypes = {
  isEdit: PropTypes.bool,
  initialValues: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  issueIndex: PropTypes.number,
};

ConfigureIssuePageModal.defaultProps = {
  isEdit: false,
  initialValues: null,
  issueIndex: null,
};


export default ConfigureIssuePageModal;
