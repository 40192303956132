import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Grid,
  Box,
  Slider,
  Typography,
} from '@material-ui/core';

import { withFormik } from 'formik';

import { ProductIdContext, UserRoleContext, BrandContext } from '@pro/web-common/containers/providers';
import { DEMO_PREFIX, PRODUCTS_PATH } from '@pro/web-common/constants/storage';

import InfoSection from '@pro/web-common/components/info-section';
import LogoUploader from '@pro/web-common/components/logo-uploader';
import Checkbox from '@pro/web-common/components/checkbox';
import SectionTitle from '@pro/web-common/components/section-title';
import DatePicker from '@pro/web-common/components/date-picker';
import CKEditorCustomized from '@pro/web-common/components/ck-editor-customized';
import CKEditorInput from '@pro/web-common/components/ck-editor-input';
import AppPreview from '@pro/web-common/components/app-preview';

import infoJson from 'constants/info.json';

import { getFirestoreTimestampDate } from '@pro/web-common/utils/date';

import { PAGE_TYPE } from 'constants/product-config';

import { formValidationSchema, logoFormats } from './config';
import { styles } from './styles';


const IssueForm = React.memo(({ onSubmit, onClose, isEdit, magazineData, ...formikProps }) => {
  const { isDemo } = useContext(UserRoleContext);
  const productId = useContext(ProductIdContext);
  const { brand } = useContext(BrandContext);

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formikProps;

  const classes = styles();

  const handleCoverImageFileChange = useCallback(({ file, filePath }) => {
    setFieldValue('newCoverImageFile', file);
    setFieldValue('coverImageFileName', file.name);
    setFieldValue('coverImage', filePath);
  }, []);

  const handleCoverImageDelete = useCallback(() => {
    setFieldValue('newCoverImageFile', null);
    setFieldValue('coverImageFileName', '');
    setFieldValue('coverImage', '');
  }, []);

  const handleCoverTitlesOpacityChange = useCallback((e, nextValue) => setFieldValue('coverTitlesOpacity', nextValue), []);
  const handleCoverTitlesMaxWidthChange = useCallback((e, nextValue) => setFieldValue('coverTitlesMaxWidth', nextValue), []);

  const appPreviewPages = useMemo(() => [
    {
      pageTypeId: PAGE_TYPE.MAGAZINE?.id,
      content: {
        config: magazineData,
        issues: [{
          ...values,
          coverImage: {
            url: values.coverImage,
          },
        }],
      },

    },
  ], [values, magazineData]);

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={10}
        >
          <Grid
            item
            xs={7}
          >
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <InfoSection infoMessage={infoJson.product.issueNameAndDateInput}>
                  <CKEditorInput
                    data={values.name}
                    onChange={(data) => setFieldValue('name', data)}
                  />
                </InfoSection>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Checkbox
                  id="showIssueName"
                  name="showIssueName"
                  value={values.showIssueName}
                  label="Show Issue Name / Date"
                  onChange={({ target: { checked } }) => setFieldValue('showIssueName', checked)}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <SectionTitle title="Cover Image" />

                <InfoSection infoMessage={infoJson.product.coverImageInput}>
                  <LogoUploader
                    id="cover-image"
                    updateLabel="Update Cover Image"
                    addLabel="Add Cover Image"
                    formats={logoFormats}
                    fileName={values.coverImageFileName}
                    filePath={values.coverImage}
                    onFileChange={({ file, filePath }) => handleCoverImageFileChange({
                      file,
                      filePath,
                    })}
                    onDelete={handleCoverImageDelete}
                    error={(errors.coverImage || errors.newCoverImageFile) && touched.coverImage}
                  />
                </InfoSection>

              </Grid>

              <Grid
                item
                xs={12}
              >
                <InfoSection infoMessage={infoJson.product.issueLiveDateInput}>
                  <SectionTitle title="Issue Live Dates" />
                </InfoSection>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
              >
                <DatePicker
                  clearable
                  fullWidth
                  variant="dialog"
                  id="startDate"
                  label="Start date"
                  value={values.startDate}
                  onChange={(value) => setFieldValue('startDate', value)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
              >
                <DatePicker
                  clearable
                  fullWidth
                  variant="dialog"
                  id="endDate"
                  label="End date"
                  value={values.endDate}
                  onChange={(value) => setFieldValue('endDate', value)}
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <InfoSection infoMessage={infoJson.product.coverHtmlInput}>
                  <SectionTitle title="Cover Titles" />
                </InfoSection>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <CKEditorCustomized
                  data={values.coverTitleHtml}
                  onChange={(data) => setFieldValue('coverTitleHtml', data)}
                  imagesPathPrefix={`${isDemo ? DEMO_PREFIX : ''}${PRODUCTS_PATH}/${productId}`}
                />
              </Grid>

              <Grid
                item
                xs={4}
              >
                <Typography
                  variant="caption"
                >
                  Cover Titles Transparency
                </Typography>

                <Box className={classes.sliderContainer}>
                  <Slider
                    value={values.coverTitlesOpacity}
                    onChange={handleCoverTitlesOpacityChange}
                    aria-labelledby="input-slider"
                  />
                  <Typography
                    variant="caption"
                  >
                    {values.coverTitlesOpacity}%
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xs={4}
              >
                <Typography
                  variant="caption"
                >
                  Max Width
                </Typography>

                <Box className={classes.sliderContainer}>
                  <Slider
                    value={values.coverTitlesMaxWidth}
                    onChange={handleCoverTitlesMaxWidthChange}
                    aria-labelledby="input-slider"
                  />
                  <Typography
                    variant="caption"
                  >
                    {values.coverTitlesMaxWidth}%
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={3}
          >
            <Grid
              container
              spacing={2}
            >
              <AppPreview
                brand={brand}
                pages={appPreviewPages}
                withNavigation={false}
                useFilteredIssues={false}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Box
              mt={2}
              display="flex"
              justifyContent="flex-end"
            >
              <Box mr={1}>
                <Button
                  color="primary"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Box>

              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                {isEdit ? 'Save' : 'Add'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
});


IssueForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  magazineData: PropTypes.shape({}).isRequired,
};

IssueForm.defaultProps = {
  isEdit: false,
};


export default withFormik({
  mapPropsToValues: ({ initialValues }) => {
    const {
      name = '',
      coverImage = null,
      coverTitlesOpacity = 100,
      coverTitlesMaxWidth = 100,
      showIssueName = true,
      coverTitleHtml = null,
      startDate = null,
      endDate = null,
      newCoverImageFile = null,
    } = initialValues || {};

    const {
      ref: defaultCoverImageFileName = '',
      url: defaultCoverImage = '',
    } = coverImage || {};

    const values = {
      coverImageFileName: defaultCoverImageFileName,
      newCoverImageFile,
      coverImage: defaultCoverImage,
      name,
      coverTitlesOpacity,
      coverTitlesMaxWidth,
      showIssueName,
      coverTitleHtml,
      startDate: startDate && getFirestoreTimestampDate(startDate),
      endDate: endDate && getFirestoreTimestampDate(endDate),
    };

    return values;
  },
  handleSubmit: (data, { props: { initialValues, onSubmit } }) => {
    const { index } = initialValues || {};

    const { coverImage, coverImageFileName, ...rest } = data;
    onSubmit(index, {
      ...rest,
      coverImage: {
        ref: coverImageFileName,
        url: coverImage,
      },
    });
  },
  validationSchema: () => formValidationSchema(),
  enableReinitialize: true,
})(IssueForm);
