import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
} from '@material-ui/core';

import IssueForm from '../issue-form';


const ConfigureIssueModal = React.memo(({ isEdit, onClose, onSubmit, initialValues, magazineData }) => (
  <Dialog
    open
    onClose={onClose}
    aria-labelledby="form-dialog-title"
    maxWidth="xl"
    fullWidth
  >
    <DialogTitle id="form-dialog-title">
      {isEdit ? 'Edit' : 'Add'}
      {' '}
      Issue
    </DialogTitle>

    <DialogContent>
      <Box mb={2}>
        <IssueForm
          isEdit={isEdit}
          initialValues={initialValues}
          onSubmit={onSubmit}
          onClose={onClose}
          magazineData={magazineData}
        />
      </Box>
    </DialogContent>
  </Dialog>
));


ConfigureIssueModal.propTypes = {
  isEdit: PropTypes.bool,
  initialValues: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  magazineData: PropTypes.shape({}).isRequired,
};

ConfigureIssueModal.defaultProps = {
  isEdit: false,
  initialValues: null,
};


export default ConfigureIssueModal;
