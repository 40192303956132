import * as Yup from 'yup';


export const logoFormats = [
  'image/png',
  'image/x-png',
  'image/jpeg',
];

export const formValidationSchema = () => Yup.object().shape({
  logoImage: Yup.string(),
  newLogoFile: Yup.mixed()
    .nullable()
    .test(
      'fileFormat',
      'Unsupported Format',
      (value) => value ? logoFormats.includes(value.type) : true,
    ),
  imprintLogoImage: Yup.string(),
  newImprintLogoFile: Yup.mixed()
    .nullable()
    .test(
      'fileFormat',
      'Unsupported Format',
      (value) => value ? logoFormats.includes(value.type) : true,
    ),
  html: Yup.string().nullable(),
});
