import React, { useMemo, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, move } from 'ramda';

import update from 'immutability-helper';

import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
} from '@material-ui/core';

import { BrandContext } from '@pro/web-common/containers/providers';

import { withFormik } from 'formik';

import SectionWithSortableSubsections from '@pro/web-common/components/section-with-sortable-subsections';
import ActionConfirmationModal from '@pro/web-common/components/action-confirmation-modal';
import PageSectionContentEditor from '@pro/web-common/components/page-section-content-editor';
import AppPreview from '@pro/web-common/components/app-preview';
import Checkbox from '@pro/web-common/components/checkbox';

import { findIndexByProp } from '@pro/web-common/utils';
import { DEFAULT_ISSUE_PAGE_CONTENT } from 'content/texts';
import { PAGE_TYPE } from 'constants/product-config';

import { getSectionDefaultProps, getSubSectionDefaultProps } from './utils';
import { styles } from './styles';


const IssuePageForm = React.memo(({ onClose, isEdit, issueIndex, ...formikProps }) => {
  const classes = styles();
  const { brand } = useContext(BrandContext);

  const {
    values,
    handleSubmit,
    setFieldValue,
  } = formikProps;

  const [expandedSectionId, setExpandedSectionId] = useState(null);

  const [isCloseConfirmationModalVisible, setIsCloseConfirmationModalVisible] = useState(false);
  const [isDeletionConfirmationModalVisible, setIsDeletionConfirmationModalVisible] = useState(false);
  const [deletingSection, setDeletingSection] = useState(null);

  const handlePageDataChange = useCallback((data) => {
    const nextPage = update(values.page, { $merge: data });
    setFieldValue('page', nextPage);
  }, [values.page]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubsectionDelete = useCallback((subsectionId, forceDeletion) => {
    const subsectionIndex = findIndexByProp(values.page.subsections, 'id', subsectionId);

    if (!forceDeletion) {
      const subsection = values.page.subsections[subsectionIndex];
      setDeletingSection(subsection);
      setIsDeletionConfirmationModalVisible(true);
    } else {
      const nextPage = update(values.page, {
        subsections: { $splice: [[subsectionIndex, 1]] },
      });

      setFieldValue('page', nextPage);
    }
  }, [values.page]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubsectionVisibilityChange = useCallback((subsectionId) => {
    const subsectionIndex = findIndexByProp(values.page.subsections, 'id', subsectionId);
    const prevVisibility = values.page.subsections[subsectionIndex].isVisible;
    const nextPage = update(values.page, {
      subsections: {
        [subsectionIndex]: { $merge: { isVisible: !prevVisibility } },
      },
    });

    setFieldValue('page', nextPage);
  }, [values.page]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeDeletionConfirmationModal = useCallback(() => {
    setDeletingSection(null);
    setIsDeletionConfirmationModalVisible(false);
  }, []);

  const handleSectionDeletionConfirm = useCallback(() => {
    closeDeletionConfirmationModal();
    handleSubsectionDelete(deletingSection.id, true);
  }, [deletingSection]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubsectionDataChange = useCallback((subsectionId, data) => {
    const subsectionIndex = findIndexByProp(values.page.subsections, 'id', subsectionId);
    const nextPage = update(values.page, {
      subsections: {
        [subsectionIndex]: { $merge: data },
      },
    });

    setFieldValue('page', nextPage);
  }, [values.page]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddSubsection = useCallback(() => {
    const nextPage = update(values.page, {
      subsections: { $push: [getSubSectionDefaultProps()] },
    });

    setFieldValue('page', nextPage);
  }, [values.page]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubsectionsSortEnd = useCallback(({ oldIndex, newIndex }) => {
    const nextSubsections = move(oldIndex, newIndex, values.page.subsections);
    const nextPage = update(values.page, {
      subsections: { $set: nextSubsections },
    });

    setFieldValue('page', nextPage);
  }, [values.page]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCloseConfirm = useCallback(() => {
    setIsCloseConfirmationModalVisible(false);
    onClose();
    setExpandedSectionId(null);
  }, [onClose]);

  const renderedSections = useMemo(() => (
    <SectionWithSortableSubsections
      sectionId={values.page.id}
      data={values.page}
      expandedSectionId={expandedSectionId}
      toggleSection={(sectionId) => setExpandedSectionId(sectionId)}
      onSectionDataChange={handlePageDataChange}
      onSubSectionDataChange={handleSubsectionDataChange}
      isSectionVisible
      onSubSectionDelete={handleSubsectionDelete}
      onSubSectionVisibilityChange={handleSubsectionVisibilityChange}
      onAddSubsection={handleAddSubsection}
      onSubsectionsSortEnd={handleSubsectionsSortEnd}
      sectionTitle="Page Title"
      subsectionTitle="Section Title"
      addSubsectionText="Add Section"
      SectionContentEditor={PageSectionContentEditor}
      SubsectionContentEditor={PageSectionContentEditor}
    />
  ), [values.page, expandedSectionId, handlePageDataChange, handleSubsectionDataChange, handleSubsectionDelete, handleSubsectionVisibilityChange, handleAddSubsection, handleSubsectionsSortEnd]);

  const appPreviewPages = useMemo(() => [
    {
      pageTypeId: PAGE_TYPE.MAGAZINE?.id,
      content: {
        issues: [{
          pages: [{
            ...values.page,
          }],
        }],
      },

    },
  ], [values]);

  return (
    <Box
      pl={2}
      pr={2}
    >
      <DialogTitle id="alert-dialog-title">
        {isEdit ? 'Edit' : 'Add'}
        {' '}
        Page
      </DialogTitle>

      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={8}
        >
          <form onSubmit={handleSubmit}>
            <DialogContent className={classes.dialogContent}>
              <Checkbox
                id="showPageInContents"
                name="showPageInContents"
                value={values.showPageInContents}
                label="Show page in contents"
                onChange={({ target: { checked } }) => setFieldValue('showPageInContents', checked)}
              />

              {renderedSections}
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
              <Button
                type="submit"
                size="small"
                color="primary"
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </Grid>
        <Grid
          item
          xs={4}
        >
          <AppPreview
            brand={brand}
            pages={appPreviewPages}
            withNavigation={false}
            showPage
          />
        </Grid>
      </Grid>


      {isDeletionConfirmationModalVisible && (
        <ActionConfirmationModal
          title="Deletion confirmation"
          text="Are you sure you want to delete section?"
          onClose={() => closeDeletionConfirmationModal()}
          onConfirm={handleSectionDeletionConfirm}
        />
      )}

      {isCloseConfirmationModalVisible && (
        <ActionConfirmationModal
          title="Are you sure you want to exit?"
          text="You have not save your edits. Updates will be missed if you exit."
          onClose={() => setIsCloseConfirmationModalVisible(false)}
          onConfirm={onCloseConfirm}
        />
      )}
    </Box>
  );
});

IssuePageForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  issueIndex: PropTypes.number.isRequired,
};

IssuePageForm.defaultProps = {};


export default withFormik({
  mapPropsToValues: ({ initialValues }) => {
    const defaultValues = initialValues && !isEmpty(initialValues) && !isEmpty(initialValues.page) ? initialValues : DEFAULT_ISSUE_PAGE_CONTENT;

    const {
      page = {
        ...getSectionDefaultProps(),
        subsections: [],
      },
    } = defaultValues;

    const values = {
      page,
      showPageInContents: defaultValues?.page?.showPageInContents === undefined ? true : defaultValues?.page?.showPageInContents,
    };

    return values;
  },
  handleSubmit: (values, { props: { onSubmit, issueIndex } }) => onSubmit({
    ...values.page,
    showPageInContents: values.showPageInContents,
  }, issueIndex),
  enableReinitialize: true,
})(IssuePageForm);
