export const MODAL_MESSAGE = {
  SIGN_UP_SUCCESS: 'Please confirm your email using the link we have just sent you. Please check your email, including your junk folder just in case.',
  SEND_RESET_LINK_SUCCESS: 'We have sent a reset link to your email address - it might take a couple of minutes to arrive. Please check your email, including your junk folder just in case.',
  UPDATE_EMAIL_SUCCESS: 'Thank you! Your email has been updated! You will need to login with the new email on the next app reload',

  UPDATE_BRAND_SUCCESS: 'Your brand configuration has been saved.',
  UPDATE_ENTERPRISE_IMAGE_SUCCESS: 'Your enterprise home image has been saved.',

  PRODUCT_CREATE_SUCCESS: 'Thanks! Your Magazine has been successfully added!',
  PRODUCT_DELETE_SUCCESS: 'Your Magazine has been deleted.',
  PRODUCT_UPDATE_SUCCESS: 'Your Magazine has been updated',
  PRODUCT_UNLOCK_SUCCESS: 'Thanks! Your Magazine is active now!',
  PRODUCT_COPY_SUCCESS: 'Thanks! Your Magazine was duplicated!',
  SAVE_PRODUCTS_ORDER_SUCCESS: 'Thanks! Order has been saved!',

  MANAGER_CREATE_SUCCESS: 'A User has been successfully created! An email with a link to activate this User has been sent.',
  MANAGER_DELETE_SUCCESS: 'This User has been deleted.',
  MANAGER_UPDATE_SUCCESS: 'This User has been updated',

  MESSAGE_CREATE_SUCCESS: 'Thanks! A message has been successfully added!',
  QUICK_MESSAGE_CREATE_SUCCESS: 'Thanks! A Quick Message has been sent to your audience!',
  MESSAGE_DELETE_SUCCESS: 'Your Message has been deleted.',
  MESSAGE_UPDATE_SUCCESS: 'Your Message has been updated',
  MESSAGE_TAGS_UPDATE_SUCCESS: 'Thanks! Message tags have been updated!',
  QUICK_MESSAGE_DELETE_SUCCESS: 'Quick Message has been deleted.',

  SUBSCRIPTION_ADD_3D_SECURE: 'To finish the payment process please follow the link below:',
  SUBSCRIPTION_ADD_SUCCESS: 'Thanks! Your subscription has been successfully added!',
  SUBSCRIPTION_RENEW_SUCCESS: 'Thanks! Your subscription is active now!',
  SUBSCRIPTION_CANCEL_SUCCESS: 'Thank you. Your subscription is cancelled. No further payments will be taken. You can always restart your subscription.',

  BANK_TRANSFER_ADD_SUCCESS: 'Thanks! Bank transfer has been successfully added!',
  BANK_TRANSFER_UPDATE_SUCCESS: 'Thanks! Bank transfer has been successfully updated!',
  BANK_TRANSFER_DELETE_SUCCESS: 'Thanks! Bank transfer has been successfully deleted!',

  SUPPORT_MESSAGE_SEND_SUCCESS: 'Thank you! Your message has been sent and one of our team will get back to you as soon as possible.',

  PAYMENT_METHOD_REMOVE_SUCCESS: 'Your card has been removed.',
  PAYMENT_METHOD_ADD_SUCCESS: 'Thank you! Your card has been added.',

  CREATE_ADMIN_SUCCESS: 'Thanks! Admin has been created!',
  DEMO_USER_CANNOT_ADD_MANAGERS: 'Demo users cannot add managers',
  DEMO_USER_CANNOT_SUBSCRIBE: 'Demo users cannot add subscriptions. Please logout, then Sign Up to proceed.',
  DEMO_USER_CANNOT_ADD_CARD: 'Demo users cannot add cards. Please Sign Up to proceed.',

  CREATE_PROFILE_SUCCESS: 'Thanks! Profile has been successfully created!',
  UPDATE_PROFILE_SUCCESS: 'Thanks! Profile has been updated!',
  RESET_PROFILE_PASSWORD_SUCCESS: 'Thanks! We have sent a password to the profile email.',
};
