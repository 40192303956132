import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Box,
  Typography,
} from '@material-ui/core';

import ContactUsForm from '@pro/web-common/components/contact-us-form';


const ContactUsSection = React.memo(({ isError, isSubmitting, onSubmit }) => (
  <Box
    bgcolor="primary.additional"
    color="white"
    pt={8}
    pb={8}
  >
    <Container maxWidth="md">
      <Box
        textAlign="center"
        mb={6}
      >
        <Typography
          variant="h3"
        >
          Request Demo
        </Typography>
      </Box>

      <ContactUsForm
        fetching={isSubmitting}
        isError={isError}
        onSubmit={onSubmit}
      />
    </Container>
  </Box>
));


ContactUsSection.propTypes = {
  isError: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

ContactUsSection.defaultProps = {
  isError: false,
  isSubmitting: false,
};


export default ContactUsSection;
