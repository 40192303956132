import { createMuiTheme } from '@material-ui/core/styles';

import defaultTheme from '@pro/web-common/theme';


const theme = createMuiTheme({
  ...defaultTheme,
  palette: {
    primary: {
      light: '#f2db0c',
      main: '#000000',
      additional: '#736806',
      another: '#F4E456',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  typography: {
    ...defaultTheme.typography,
    fontFamily: 'Libre Franklin',
  },
});

export const LOGO_WIDTH = '200px';

export default theme;
