import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Box,
  FormControl,
} from '@material-ui/core';
import { withFormik } from 'formik';

import { ProductIdContext, UserRoleContext, BrandContext } from '@pro/web-common/containers/providers';
import { DEMO_PREFIX, PRODUCTS_PATH } from '@pro/web-common/constants/storage';

import SectionTitle from '@pro/web-common/components/section-title';
import Select from '@pro/web-common/components/select';
import LogoUploader from '@pro/web-common/components/logo-uploader';
import InfoSection from '@pro/web-common/components/info-section';
import CKEditorCustomized from '@pro/web-common/components/ck-editor-customized';
import AppPreview from '@pro/web-common/components/app-preview';
import ColorPicker from '@pro/web-common/components/color-picker';

import { typefaceValues } from 'constants/brand-config';
import infoJson from 'constants/info.json';

import { getFakePreviewContent } from './utils';
import { formValidationSchema, logoFormats } from './config';


const IssueForm = React.memo(({ onSubmit, onClose, ...formikProps }) => {
  const { isDemo } = useContext(UserRoleContext);
  const productId = useContext(ProductIdContext);
  const { brand } = useContext(BrandContext);

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formikProps;

  const handleLogoFileChange = useCallback(({ file, filePath }) => {
    setFieldValue('newLogoFile', file);
    setFieldValue('logoFileName', file.name);
    setFieldValue('logoImage', filePath);
  }, []);

  const handleImprintLogoFileChange = useCallback(({ file, filePath }) => {
    setFieldValue('newImprintLogoFile', file);
    setFieldValue('imprintLogoFileName', file.name);
    setFieldValue('imprintLogoImage', filePath);
  }, []);

  const handleLogoDelete = useCallback(() => {
    setFieldValue('newLogoFile', null);
    setFieldValue('logoFileName', '');
    setFieldValue('logoImage', '');
  }, []);

  const handleImprintLogoDelete = useCallback(() => {
    setFieldValue('newImprintLogoFile', null);
    setFieldValue('imprintLogoFileName', '');
    setFieldValue('imprintLogoImage', '');
  }, []);

  const appPreviewPages = useMemo(() => [getFakePreviewContent(values)], [values]);

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={10}
        >
          <Grid
            item
            xs={7}
          >
            <Grid
              item
              xs={12}
            >
              <Box mb={2}>
                <FormControl
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    id="typeface"
                    name="typeface"
                    options={typefaceValues}
                    value={values.typeface}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    withFontFamily
                    label="Font / Typeface"
                  />
                </FormControl>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <LogoUploader
                id="magazine-logo"
                updateLabel="Update Magazine Logo"
                addLabel="Add Magazine Logo"
                formats={logoFormats}
                fileName={values.logoFileName}
                filePath={values.logoImage}
                onFileChange={({ file, filePath }) => handleLogoFileChange({
                  file,
                  filePath,
                })}
                onDelete={handleLogoDelete}
                error={(errors.logoImage || errors.newLogoFile) && touched.logoImage}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Box
                mt={3}
                pr={4}
              >
                <InfoSection infoMessage={infoJson.brand.topBarColour}>
                  <SectionTitle title="Imprint" />
                </InfoSection>
              </Box>

            </Grid>

            <Grid
              item
              xs={12}
            >
              <Box mb={2}>
                <CKEditorCustomized
                  data={values.imprintHtml}
                  onChange={(data) => setFieldValue('imprintHtml', data)}
                  imagesPathPrefix={`${isDemo ? DEMO_PREFIX : ''}${PRODUCTS_PATH}/${productId}`}
                  error={errors.imprintHtml && touched.imprintHtml}
                />
              </Box>
            </Grid>

            <Box
              width="40%"
            >
              <ColorPicker
                label="Imprint Background Colour"
                color={values.imprintBackgroundColor}
                onColorChange={(value) => setFieldValue('imprintBackgroundColor', value)}
              />
            </Box>

            <Box
              width="40%"
            >
              <ColorPicker
                label="Imprint Font Colour"
                color={values.imprintFontColor}
                onColorChange={(value) => setFieldValue('imprintFontColor', value)}
              />
            </Box>

            <Box
              width="40%"
            >
              <ColorPicker
                label="Contents Header Colour"
                color={values.sectionBackgroundColor}
                onColorChange={(value) => setFieldValue('sectionBackgroundColor', value)}
              />
            </Box>

            <Box
              width="40%"
            >
              <ColorPicker
                label="Contents Header Text Colour"
                color={values.sectionTextColor}
                onColorChange={(value) => setFieldValue('sectionTextColor', value)}
              />
            </Box>

            <Grid
              item
              xs={12}
            >
              <LogoUploader
                id="imprint-logo"
                updateLabel="Update Imprint Logo"
                addLabel="Add Imprint Logo"
                formats={logoFormats}
                fileName={values.imprintLogoFileName}
                filePath={values.imprintLogoImage}
                onFileChange={({ file, filePath }) => handleImprintLogoFileChange({
                  file,
                  filePath,
                })}
                onDelete={handleImprintLogoDelete}
                error={(errors.imprintLogoImage || errors.imprintLogoFileName) && touched.imprintLogoImage}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Box
                mt={2}
                display="flex"
                justifyContent="flex-end"
              >
                <Box mr={1}>
                  <Button
                    color="primary"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Grid
            item
            xs={5}
          >
            <AppPreview
              brand={brand}
              pages={appPreviewPages}
              withNavigation={false}
              showImprintPage
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
});


IssueForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    logo: PropTypes.shape({}),
    imprintLogo: PropTypes.shape({}),
    typeface: PropTypes.string,
  }).isRequired,
};

IssueForm.defaultProps = {

};


export default withFormik({
  mapPropsToValues: ({ initialValues }) => {
    const {
      logo = null,
      imprintLogo = null,
      typeface = typefaceValues[0].value,
      imprintHtml = null,
      newLogoFile = null,
      newImprintLogoFile = null,
      imprintBackgroundColor = {
        r: 237,
        g: 237,
        b: 11,
        a: 1,
      },
      imprintFontColor = {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
      sectionBackgroundColor = {
        r: 121,
        g: 231,
        b: 114,
        a: 1,
      },
      sectionTextColor = {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
    } = initialValues || {};

    const {
      ref: defaultLogoFileName = '',
      url: defaultLogoImage = '',
    } = logo || {};

    const {
      ref: defaultImprintLogoFileName = '',
      url: defaultImprintLogoImage = '',
    } = imprintLogo || {};

    const values = {
      logoFileName: defaultLogoFileName,
      newLogoFile,
      logoImage: defaultLogoImage,
      imprintLogoFileName: defaultImprintLogoFileName,
      newImprintLogoFile,
      imprintLogoImage: defaultImprintLogoImage,
      typeface,
      imprintHtml,
      imprintBackgroundColor,
      imprintFontColor,
      sectionBackgroundColor,
      sectionTextColor,
    };

    return values;
  },
  handleSubmit: (data, { props: { onSubmit } }) => {
    const { logoFileName, logoImage, imprintLogoFileName, imprintLogoImage, ...rest } = data;
    onSubmit({
      ...rest,
      logo: {
        ref: logoFileName,
        url: logoImage,
      },
      imprintLogo: {
        ref: imprintLogoFileName,
        url: imprintLogoImage,
      },
    });
  },
  validationSchema: () => formValidationSchema(),
  enableReinitialize: true,
})(IssueForm);
